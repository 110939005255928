<template>
    <div class="container">
        <div class="breadcrumb">
            <p class="topic_path m-0"><router-link class="ashen-link" :to="{ name: 'AccountSettings'}">{{ $t("account.default.pageTitle") }}</router-link><i class="fas fa-angle-right form-control-color"></i> {{ $t("account.editWithoutPassword.nationalityChange") }}</p>
        </div>
        <div class="card text-first border-0">
            <div class="card-body max-800 mx-auto col-12">
                <div class="row">
                    <h3 class="fw-bold mb-4">{{ $t("account.editWithoutPassword.nationalityChange") }}</h3>
                </div>
                <div class="row  mx-auto mt-0 mt-sm-4">
                    <select class="form-control form-select citizenship" v-model="nationalityId">
                      <option v-for="obj in nationalityList"
                              v-bind:value="obj.nationalityId">{{obj.nationalityName}}</option>
                    </select> 
                </div>
                <div class="row">
                    <div class="form-error" id="err_country" v-show="errors['nationalityId']">{{ errors['nationalityId'] }}</div>
                </div>
                <div class="row m-t-20">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
                        <button @click="$router.push('account')"
                                class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn">{{ $t("account.editWithPassword.btnCancel") }}
                        </button>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
                        <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                                v-on:click="doChange">
                            {{ $t("account.editWithPassword.btnChange") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Nationality, NationalityItems, SetUpdateUser} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditCountry",
  data() {
    return {
      nationalityList: [],
      nationalityId: 1,
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = this.nationalityId
      params.nationalityIdUpdate = true
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("account.editWithoutPassword.toastSuccessChangeNationality")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.nationalityId = jsonObject.nationalityId
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getNationality () {
      const token = this.config.token
      try {
        const api = Common.createSystemApi(token)
        const jsonObject = await api.systemNationalitiesGetAsync()
        const result = NationalityItems.createFromJson(jsonObject)
        this.nationalityList = result.items

      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getNationality()
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getNationality()
      await this.getUser()
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 576px) {
    .topic_path {
        display: none;
    }
    .border-top {
        border: 0px !important;
    }
}
</style>
